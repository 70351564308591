$(document).ready(function() {
	var menuButton = $('.menu-button');
	var menu = $('nav.navigation');

	menuButton.on('click', function(e) {
		menu.toggle();
		e.stopPropagation();
	});

	$('body').on('click', function(e) {
		if (menu.css('display') === 'block') { // If mobile menu is enaged
			if (!($(e.target).closest(".navigation").length)) { // If we clicked on the see-through part
				menu.toggle();
			}
		}
	});
});